var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    { staticClass: "main-box flex-container" },
    [
      _c("CommonTreeNew", {
        ref: "commonTreeCore",
        attrs: {
          treeTitle: "作业票类型",
          "node-key": "id",
          isLeafIcons: "",
          isShowdig: "",
          defaultExpandedKeys: [],
          defaultProps: _vm.defaultProps,
          showCheckbox: false,
          isShowdig: false,
          url: "/api/sinoma-system/dict-biz/dictionary?code=work_permit_type",
          urlParmas: {},
        },
        on: {
          getTreeAdd: _vm.getTreeAdd,
          getTreeDelete: function ($event) {
            return _vm.rowDel([$event], 0)
          },
          getNodeClick: _vm.gridRowClick,
          getTreeData: _vm.commonTreeData,
        },
      }),
      _c(
        "div",
        { staticClass: "table-box" },
        [
          _c("head-layout", {
            attrs: {
              "head-btn-options": _vm.corePersonnelHeadBtnOptions,
              "head-title": "作业票安全措施",
            },
            on: {
              "head-export": _vm.headExport,
              "head-del": _vm.headDel,
              handleImport: _vm.handleImport,
              "head-add": _vm.addjcx,
            },
          }),
          _c("grid-layout", {
            ref: "gridLayOut",
            staticClass: "gridLayOut",
            attrs: {
              "table-options": _vm.corePersonnelTableOption,
              "data-total": _vm.page.total,
              page: _vm.page,
              "table-data": _vm.tableData1,
              "table-loading": _vm.TableLoading1,
            },
            on: {
              "page-current-change": _vm.onload1,
              "page-size-change": _vm.onload1,
              "gird-handle-select-click": _vm.selectionChange,
            },
            scopedSlots: _vm._u([
              {
                key: "customBtn",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.addjcx(row)
                          },
                        },
                      },
                      [_vm._v("编辑\n        ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.rowDel([row], 1)
                          },
                        },
                      },
                      [_vm._v("删除\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.jcxflModel
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              attrs: {
                title: "作业活动清单",
                visible: _vm.jcxflModel,
                width: "30%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.jcxflModel = $event
                },
              },
            },
            [
              _c("avue-form", {
                ref: "avueForm",
                attrs: { option: _vm.jcxflOption },
                on: {
                  "reset-change": _vm.resetChange,
                  submit: _vm.handleSubmit,
                },
                model: {
                  value: _vm.jcxflForm,
                  callback: function ($$v) {
                    _vm.jcxflForm = $$v
                  },
                  expression: "jcxflForm",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "avue-dialog__footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.jcxflModel = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                          "\n      "
                      ),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.avueForm.submit()
                        },
                      },
                    },
                    [_vm._v("\n        保存\n      ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.jcxflModel1
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              attrs: {
                title: "安全措施项",
                visible: _vm.jcxflModel1,
                width: "50%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.jcxflModel1 = $event
                },
              },
            },
            [
              _c("avue-form", {
                attrs: { option: _vm.jcxflOption1 },
                on: {
                  "reset-change": _vm.resetChange1,
                  submit: _vm.handleSubmit1,
                },
                model: {
                  value: _vm.jcxflForm1,
                  callback: function ($$v) {
                    _vm.jcxflForm1 = $$v
                  },
                  expression: "jcxflForm1",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }